@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
    display: grid;
    gap: 16px;
    margin-top: 20px;
    // flex-wrap: wrap;
    // grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
    @include devices(desktop) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include devices(tablet) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include devices(xlMobile) {
        grid-template-columns: repeat(1, 1fr);
    }
    @include devices(mobile) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.tokenCard {
    // max-width: 400px;
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    gap: 18px;
    color: $color-white;
    background-color: $background-secondary;
    padding: 6px 16px;
    border: 1px solid transparent;
    border-radius: 16px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s;
    & p {
        margin: 2px 0px;
    }
    border-color: var(--color_primary);
}
.tokenCard:hover {
    grid-template-columns: max-content 1fr max-content max-content;
    position: relative;
    padding-right: 40px;

    &::after {
        content: ">";
        width: 40px;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color_white);
        background-color: var(--color_primary);
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        font-size: 20px;
        font-weight: 900;
    }
}
.tokenCardLight {
    background-color: $color-white;
    color: $color-black;
}
.wrapper {
    display: flex;
    justify-content: space-between;
}

.tokenLogo {
    width: 35px;
    height: 35px;
}
.tokenLogo2 {
    width: 35px;
    height: 35px;
    margin-left: -10px;
}

.name {
    font-size: 16px;
    font-weight: 600;
}
.networkName {
    font-size: 12px;
    font-weight: 400;
    margin-left: 4px;
    color: var(--color_primary);
}
.balance {
    font-size: 12px;
    font-weight: 400;
}
.usdBalance {
    margin-left: auto;
    font-weight: 500;
}
