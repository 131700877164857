.sideitems {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  color: white;
  padding: 10px 8px;
  border-radius: 10px;
  cursor: pointer;
}

.sideitems--light {
  color: #8798ad;
}

.sideitems--selected {
  background-color: var(--color_primary);
  color: white;
  width: 170px;
}

.sideitems:hover {
  color: var(--color_primary);
}

.sideitems--selected:hover {
  color: white;
}

.sidebar_title {
  font-weight: bold;
  margin: 0px;
}