@import "../../../styles/variables.scss";
@import "../../../styles/_mixins.scss";

.container {
    padding: 40px 60px 50px;
    @include devices(tablet) {
        width: initial;
    }
    @include devices(mobile) {
        padding: 18px 45px 30px;
    }
}

.heading {
    color: var(--color_text);
    position: relative;
}
.warning {
    transform: rotate(30deg) translate(-20px, 10px) scale(200);
    color: var(--background_primary);
}
.caption {
    font-size: large;
    font-weight: bold;
    color: var(--color_text);
    position: relative;
}
.message {
    color: var(--color_grey);
    max-width: 520px;
    position: relative;
}
.confirm {
    margin-top: 30px;
    color: var(--color_text);
    & label {
        margin-left: 8px;
    }
}
.note {
    margin-bottom: 0px;
    color: var(--color_text);
}
.key {
    color: #b6c1ce;
    background-color: var(--background_primary);
    width: 100%;
    border-radius: 10px;
    padding: 16px;
    margin-top: 12px;
    box-sizing: border-box;
    position: relative;

    & input {
        margin: 0px;
        font-size: 13px;
        font-weight: bold;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: transparent;
        color: inherit;
        border: none;
        outline: none;

        @include devices(tablet) {
            width: 65%;
        }
        @include devices(mobile) {
            width: 50%;
        }
    }

    .copyIcon {
        position: absolute;
        right: 100px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: inherit;
    }

    .textButton {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--color_primary);
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
    }
    .textButton:disabled {
        color: var(--color_disabled);
        cursor: not-allowed;
    }
}
