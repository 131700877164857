@import "../../styles/variables.scss";
@import "../../styles/mixins";

.container {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    overflow: auto;

    .heading {
        color: var(--color_text);
        text-align: center;
    }

    .buttonWrapper {
        display: flex;
        align-items: center;
        gap: 24px;

        .logoWrapper {
            display: flex;
            align-items: center;
            gap: 24px;
            .logoCircle {
                width: 56px;
                height: 56px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--background_secondary);
            }

            @include devices(xlMobile) {
                .onlyLargeScreen {
                    display: none;
                }
            }
        }
    }

    .tokenBalancesContainer {
        width: 100%;
        min-height: 100px;
        background: var(--background_secondary);
        border-radius: 16px;
        padding: 16px 20px;
        box-sizing: border-box;

        .balanceHeading {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .tokensWrapper {
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
            @include devices(desktop) {
                grid-template-columns: repeat(3, 1fr);
            }
            @include devices(tablet) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include devices(xlMobile) {
                grid-template-columns: repeat(1, 1fr);
            }
            @include devices(mobile) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .tokenCard {
            // max-width: 400px;
            width: 100%;
            display: grid;
            grid-template-columns: max-content 1fr max-content;
            align-items: center;
            gap: 18px;
            color: $color-white;
            background-color: $background-secondary;
            padding: 6px 16px;
            border: 1px solid transparent;
            border-radius: 16px;
            box-sizing: border-box;
            cursor: pointer;
            transition: 0.3s;
            & p {
                margin: 2px 0px;
            }
            border-color: var(--color_primary);
        }
        .tokenCard:hover {
            grid-template-columns: max-content 1fr max-content max-content;
            position: relative;
            padding-right: 40px;

            &::after {
                content: ">";
                width: 40px;
                height: 100%;
                position: absolute;
                right: 0px;
                top: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--color_white);
                background-color: var(--color_primary);
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
                font-size: 20px;
                font-weight: 900;
            }
        }
        .tokenCardLight {
            background-color: $color-white;
            color: $color-black;
        }
        .wrapper {
            display: flex;
            justify-content: space-between;
        }

        .tokenLogo {
            width: 35px;
            height: 35px;
        }
        .tokenLogo2 {
            width: 35px;
            height: 35px;
            margin-left: -10px;
        }

        .name {
            font-size: 16px;
            font-weight: 600;
        }
        .networkName {
            font-size: 12px;
            font-weight: 400;
            margin-left: 4px;
            color: var(--color_primary);
        }
        .balance {
            font-size: 12px;
            font-weight: 400;
        }
        .usdBalance {
            margin-left: auto;
            font-weight: 500;
        }
    }
}


.inputWrapper {
    background-color: var(--background_primary);
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    & input {
        background-color: transparent;
        border: none;
        color: var(--color_text);
        padding: 5px;
        outline: none;
        width: 100%;
    }
}