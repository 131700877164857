.loader {
    width: 18px;
    height: 18px;
    border: 1px solid #012243;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
