.deprecated_farms_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 50%;
    color: var(--color_grey);
    background: var(--background_secondary);
    box-shadow: var(--rk-shadows-connectButton);
    cursor: pointer;
}

.deprecated_farms_select {
    position: absolute;
    top: 110%;
    color: var(--color_text);
    right: 0;
    background: var(--background_secondary);
    border-radius: 5px;
    border-top-right-radius: 0px;
    width: max-content;
    padding: 8px 0px;
    box-shadow: var(--rk-shadows-connectButton);
    animation: scaleAnim 100ms ease-out 1 forwards;
    transform-origin: 0 0;
}

.dotMenuContainer {
    position: relative;
    right: 0;
    top: 0;
}

@keyframes scaleAnim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.deprecated_farms_select > p {
    margin: 0px;
    padding: 10px 12px;
    width: 140px;
    text-align: center;
    font-weight: 500;
}

.deprecated_farms_select > p:hover {
    color: var(--color_primary);
    font-weight: 700;
}

.checkboxRow {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    p,
    input {
        margin: 0px;
    }
    input {
        margin-top: 2px;
    }
}

// Toggle button

.switchContainer {
    display: flex;
    align-items: flex-end;
    justify-items: center;
}

/* The switch - the box around the slider */
.switch {
    margin-left: 20px;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
