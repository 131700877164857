// @mixin centerTranslate($scale: 1, $rotate: 0) {
//     position: absolute;
//     left: 50vw;
//     top: 50vh;
//     transform: translateX(-50%) translateY(-50%) scale($scale) rotate($rotate);
//   }

@mixin farmDescriptionWithInputContainer() {
    display: grid;
    margin-top: 10px;
    gap: 20px;
    padding: 0 30px;
    grid-template-columns: 1fr minmax(0, 600px);
    
    @include devices(tablet) {
        grid-template-columns: 1fr;
        flex-direction: column;
        padding-bottom: 30px;
    }
}

@mixin farmDescriptionWithInputInput() {
    border: 0.5px solid #043b71;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    position: relative !important;
    max-width: 600px;

    
}

@mixin button() {
    background-color: var(--color_primary);
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 17px;
    font-weight: bold;
    padding: 18px 5px;
    min-width: 200px;
    min-height: 50px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

// $breakpoint is the variable that can have several values
@mixin devices($breakpoint) {
    //the name of the mixin is devices

    @if $breakpoint == desktop {
        @media only screen and (max-width: 1200px) {
            @content;
        }
    }

    @if $breakpoint == tablet {
        @media only screen and (max-width: 768px) {
            @content;
        }
    }

    @if $breakpoint == xlMobile {
        @media only screen and (max-width: 650px) {
            @content;
        }
    }

    @if $breakpoint == mobile {
        @media only screen and (max-width: 500px) {
            @content;
        }
    }
}
