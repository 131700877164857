.details_section {
    width: 100%;
    box-sizing: border-box;
}

.detials_rate {
    padding: 40px;
    padding-bottom: 10px;
}

.details_zapToggle {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 0px 40px;
}

.details_zapToggle > div {
    grid-column-start: 2;
}

.details_pair_name {
    font-size: 17px;
    font-weight: 700;
    color: white;
    margin: 0;
    margin-left: 15px;
}

.details_pair_name--light {
    color: black;
}

.details_logo1 {
    width: 50px;
    height: 50px;
    border-radius: 999px;
}

.details_logo2 {
    width: 50px;
    height: 50px;
    margin-left: -10px;
    border-radius: 999px;
}

.details_dropdrown_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.mini_details_image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 999px;
}

.token_details {
    display: flex;
    width: 100%;
}

.details_single_token {
    padding: 10px;
    color: white;
    padding-left: 9px;
    background-color: #001428;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    width: 200px;
}

.details_single_token--light {
    color: black;
    background-color: rgb(231, 240, 247);
}

.details_top_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
    padding: 40px;
}

.detailed_position {
    color: white;
    background-color: #001428;
    border-radius: 15px;
}

.detailed_position--light {
    color: black;
    background-color: rgb(231, 240, 247);
}

.detailed_position_total {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #043b71;
}

.detailed_position_total--light {
    border-bottom: 1px solid white;
}

.detailed_header {
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
}

.unstaked_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.detailed_unstaked_pairs {
    font-weight: 500;
    margin-left: 5px;
}

.unstaked_images1 {
    width: 25px;
    height: 25px;
    border-radius: 999px;
}

.unstaked_images2 {
    width: 25px;
    height: 25px;
    margin-left: -5px;
    border-radius: 999px;
}

.unstaked_details_header {
    display: flex;
    align-items: center;
}

.details_retract {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.details_retract:hover {
    cursor: pointer;
}

.details_retract--light {
    color: black;
}

.details_retract_description {
    font-size: 17px;
    font-weight: 600;
    padding: 10px 5px;
}

.farm_apy_lp_graph_container_details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
}

@media (max-width: 1200px) {
    .details_zapToggle {
        grid-template-columns: 1fr;
    }

    .details_zapToggle > div {
        justify-content: center;
        grid-column-start: 1;
    }

    .details_dropdrown_header {
        justify-content: center;
    }

    .token_details {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .farm_apy_lp_graph_container_details {
        display: grid;
        grid-template-columns: 1fr;
    }
    .details_top_container {
        padding: 10px;
        flex-direction: column;
        justify-content: center;
    }

    .details_pair_name {
        font-size: 16px;
    }

    .details_logo1,
    .details_logo2 {
        width: 45px;
        height: 45px;
    }

    .token_details {
        margin-bottom: 5px;
    }

    .details_single_token {
        font-size: 14px;
    }

    .details_retract_description {
        font-size: 13px;
    }

    .details_section {
        padding: 10px;
    }

    .detailed_position_total {
        font-size: 16px;
    }

    .detailed_header {
        font-size: 13px;
    }
}

@media (max-width: 500px) {
    .token_details {
        flex-direction: column;
        gap: 10px;
        margin-top: 16px;
    }

    .details_single_token {
        width: 100%;
        margin-right: 0;
        box-sizing: border-box;
    }
}
