.container {
    background-color: var(--background_secondary);
    width: 100%;
    margin: 20px 0px;
    padding: 24px;
    border-radius: 16px;
    box-sizing: border-box;
}

.notSignedIn {
    width: 100%;
    min-height: 400px;
    margin: 20px 0px;
}
