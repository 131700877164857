.proposal-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px;
    border: 1px solid var(--color_primary);
    border-radius: 8px;
    min-width: 340px;
    width: 100%;
    width: -webkit-fill-available;
    box-sizing: border-box;
    font-size: 14px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    font-size: large;
    font-weight: 600;
}

.status {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid var(--color_primary);
    white-space: pre;
}

.status.in-progress {
    background-color: var(--color_primary);
    color: var(--color_white);
}

.description {
    height: 100%;
    max-height: 4.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.viewLink {
    color: var(--color_primary);
}
