.container {
    width: auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
        margin: 0px;
    }
}

.heading {
    font-size: 20px;
    font-weight: bold;
}
.value {
    font-size: 40px;
    font-weight: bold;
}
