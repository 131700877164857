@import "../../../styles/_mixins.scss";

.container {
    padding: 50px 58px 50px 58px;
    // width: 400px;
    min-height: 330px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 26px;
    @include devices(tablet) {
        width: initial;
        display: block;
    }
    @include devices(mobile) {
        padding: 50px 58px;
    }
}

.left {
    text-align: left;
}

.heading {
    & h1 {
        margin: 0px;
        margin-bottom: 20px;
        font-size: 52px;
    }
}
.caption {
    font-size: large;
    font-weight: bold;
    margin: 0px;
}
.key {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--color_grey);
    margin-bottom: 20px;
    .copyIcon {
        cursor: pointer;
        color: inherit;
    }
    & p {
        max-width: 377px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0px;
    }
}
.extraText{
    width: 250px;
    @include devices(tablet) {
        display: none;
    }
}
// .qrWrapper {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     & img{
//         width: 200px;
//         height: 200px;
//     }
// }
.qrCode {
    width: fit-content;
    height: fit-content;
    text-align: center;
    padding: 12px;
    margin: 0px auto;
    border: 12px solid var(--color_primary);
    border-radius: 12px;
    box-shadow: 4px 4px 34px rgba(0, 0, 0, 0.15);
}
