@import "../../../styles/variables.scss";
@import "../../../styles/_mixins.scss";

.transferForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 540px;
    @include devices(tablet) {
        width: 100%;
    }
}

.row{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    position: relative;
}

.label{
    font-size: larger;
    font-weight: bolder;
}

.button {
    font-size: larger !important;
    font-weight: bold !important;
    margin-top: 32px;
    margin-bottom: 8px;
}

.inputWrapper {
    background-color: var(--background_primary);
    border-radius: 10px;
    padding: 5px 10px;
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    & input {
        background-color: transparent;
        border: none;
        color: var(--color_text);
        padding: 5px;
        outline: none;
        width: 100%;
        height: 35px;
        font-size: 1rem;
        font-weight: 500;
    }
}

.maxContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.maxBtn {
    color: var(--color_primary);
    background: transparent;
    border: 1px solid var(--color_primary);
    border-radius: 5px;
    outline: none;
    padding: 2px 10px;
    margin: 9px 0px;
    font-weight: bold;
    cursor: pointer;
}
.maxBtn:hover {
    color: $color_white;
    background-color: var(--color_primary);
}
.maxBtn:active {
    transform: scale(0.98);
}