@import "../../styles/variables";
@import "../../styles/mixins";

.container {
    border: 1px solid;
    border-radius: 8px;
    padding: 8px;
    border-color: #63cce0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;    
}
.containerLight {
    @extend .container;
    background-color: #e7f0f7;
    color: #01224386;
}

.containerDark {
    @extend .container;
    background-color: #032a51;
    color: #ffffff79;
}
