.sidebar_bg {
    background-color: #001428;
    height: 100vh;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    top: 0;
    border-right: 1px solid #022b55;
    justify-content: flex-start;
    box-sizing: border-box;
    overflow-y: auto;
}

.sidebar_bg--light {
    background-color: #f5f6f9;
    border-right: 1px solid #d9d9d9;
}

.contrax_logo {
    width: 90%;
    margin-bottom: 20px;
}

.side_items {
    flex: 7;
    margin-top: 20px;
}

@media (max-width: 650px) {
    .sidebar_footer {
        padding-bottom: 120px;
    }
}
