.darkmode_toggle_container {
  display: flex;
  align-items: center;
  justify-content: left;
}

.lighttoggle:hover {
  cursor: pointer;
}

.lighttoggle {
  background-color: var(--color_primary);
  padding: 3px;
  width: 63px;
  border-radius: 20px;
  margin: 10px;
  margin-left: 10px;
  transition: 0.3s ease-in 0s;
}

.lighttoggle--light {
  background-color: #8798ad;
}

.lighttoggle_switch {
  background-color: white;
  float: right;
  border-radius: 999px;
  width: 20px;
  height: 20px;
  padding: 2px;
  transition: 0.3s ease-in 0s;
}

.lighttoggle_switch--light {
  background-color: white;
  float: left;
}

.label1 {
  color: white;
  font-size: 20px;
}

.label1--light {
  color: #8798ad;
}

.label2 {
  color: white;
  font-size: 20px;
}

.label2--light {
  color: #8798ad;
}