.vaults_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    // grid-template-columns: repeat(auto-fit, minmax(280px, 400px));
    margin-top: 20px;
}

.vaults {
    margin: 5px;
}
