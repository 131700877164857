@import "./_mixins.scss";

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-button {
    @include button();
    @include devices(tablet) {
        padding: 15px 5px !important;
    }
}

.custom-button-light {
    @include button();
    background-color: var(--color_primary);
}

.custom-button-disabled {
    @include button();
    color: var(--color_disabled);
    background-color: var(--background_primary);
    cursor: not-allowed;
}

.custom-button:disabled,
.custom-button[disabled] {
    color: var(--color_disabled);
    background-color: var(--background_primary);
    cursor: not-allowed;
}
.custom-button-light:disabled,
.custom-button-light[disabled] {
    color: var(--color_disabled);
    background-color: var(--background_primary);
    cursor: not-allowed;
}

.custom-button:active {
    transform: scale(0.95);
}
.custom-button-light:active {
    transform: scale(0.95);
}

.outlinedContainer {
    background: var(--background_secondary);
    width: max-content;
    border-radius: 16px;
    border: 2px solid var(--color_primary);
    color: var(--color_text);
    padding: 16px 20px;
}

.colorContainer {
    background: var(--color_primary);
    color: white;
    width: max-content;
    border-radius: 16px;
    padding: 16px 20px;
}

a {
    color: inherit;
}

p,
h5,
h6,
h4,
h3,
h2,
h1 {
    margin-block-end: 0;
    margin-block-start: 0;
    line-height: normal;
}
h4 {
    font-size: 1.75em;
}
h5 {
    font-size: 1.5em;
}
h6 {
    font-size: 1.25em;
}

select {
    /* Reset */
    // appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    // width: 20em;
    height: 2em;
    padding: 0 0em 0 1em;
    margin-left: auto;
    color: white;
    border-radius: 0.25em;
    background: black;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* <option> colors */
    option {
        color: inherit;
        background-color: #320a28;
    }
    /* Remove focus outline */
    &:focus {
        outline: none;
    }
    /* Remove IE arrow */
    &::-ms-expand {
        display: none;
    }
}
[data-rk] ._9pm4ki3 {
    z-index: 99 !important;
}

::placeholder {
    color: #979797;
}

[data-rk] ._1am14410 {
    scrollbar-width: auto;
}

[data-rk] ._1am14410::-webkit-scrollbar {
    height: 7px;
    display: initial;
}

/* Track */
[data-rk] ._1am14410::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #b4b4b4;
    // border-radius: 10px;
}

/* Handle */
[data-rk] ._1am14410::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}

/* Handle on hover */
[data-rk] ._1am14410::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}
