@import "../../../styles/variables.scss";
@import "../../../styles/_mixins.scss";

.backdrop {
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000099;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    backdrop-filter: blur(3px);
}
.container {
    min-height: 200px;
    color: $color_white;
    background-color: $background_secondary;
    box-shadow: 20px 30px 25px rgb(0 0 0 / 15%);
    border-radius: 20px;
    padding: 35px 50px;
    position: relative;
    overflow: hidden;
    @include devices(tablet) {
        min-width: 100px;
        width: 80%;
        padding: 30px;
    }
}
.container_light {
    background-color: $color_white;
    color: $color_black;
}