.selectWrapper {
    position: relative;
    width: fit-content;
    justify-self: end;
}
.select {
    position: relative;
    width: 160px;
    color: var(--color_text);
    background: var(--background_primary);
    padding: 6px 18px;
    padding-right: 30px;
    border: 1px solid var(--color_primary);
    border-radius: 4px;
    cursor: pointer;
}
.arrow{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);

    &.rotate {
        transform: rotateZ(180deg) translateY(50%);
    }
}
.options {
    position: absolute;
    right: 0;
    z-index: 100;
    width: 160px;
    color: var(--color_grey);
    background: var(--background_primary);
    border: 1px solid var(--color_primary);
    border-radius: 4px;
    padding: 6px 24px;
    top: 120%;
}
.option {
    // white-space: nowrap;
    padding: 8px 4px;
    border-bottom: 1px solid var(--color_grey);
    cursor: pointer;

    &:last-child {
        border-bottom: transparent;
    }
    &:hover {
        color: var(--color_primary);
    }
}