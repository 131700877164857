.topbar {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 16px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #022b55;
}

.topbar--light {
  border-bottom: 1px solid #d9d9d9;
}

.connect_wallet {
  background-color: rgb(89, 179, 247);
  color: white;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: bold;
  justify-content: center;
  width: fit-content;
  border-radius: 15px;
}

.connect_wallet:hover {
  cursor: pointer;
  background-color: var(--color_primary);
}

.connect_wallet2 {
  display: flex;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  justify-content: center;
  width: fit-content;
  border-radius: 15px;
}

.connect_wallet2--light {
  color: black;
}

.connected_wallet_address {
  font-weight: bold;
  color: white;
  display: flex;
  width: fit-content;
  justify-content: center;
}

.connected_wallet_address--light {
  color: black;
}

.wallet_address>p {
  margin-left: 30px;
}

.wallet_address--light {
  color: black;
}

.ethBal {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 400;
  color: var(--color_primary);
  background-color: #032a51;
  border-radius: 20px;
}

.wrongNetwork {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 400;
  color: #f55858;
  background-color: #ffdcdc;
  border-radius: 20px;
}

.ethBal--light {
  color: var(--color_primary);
  background-color: #dcf2ff;
}

.wrongNetwork--light {
  color: #f55858;
  background-color: #ffdcdc;
}

.connected_wallet {
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  background-color: #012243;
  border: 1px solid #032a51;
}

.connected_wallet>p {
  margin-right: 5px;
}

.connected_wallet:hover {
  background-color: #032a51;
  cursor: pointer;
}

.connected_wallet--light {
  background-color: rgb(246, 246, 246);
  border: 1px solid #d9d9d9;
}

.connected_wallet--light:hover {
  background-color: white;
}

.wallet {
  font-size: 30px;
}

.address {
  font-weight: 400;
}

.hamburger_menu {
  display: none;
}

.burger-icon {
  display: none;
}

.dropdown_hamburger_menu {
  display: flex;
  flex-direction: column;
  width: 45vw;
  max-width: 200px;
  padding: 10% 5%;
  padding-left: 15%;
  border: 0.5px solid #8798ad;
  background-color: #012243;
  z-index: 1200 !important;
  position: absolute;
  top: 0;

}

.dropdown_hamburger_menu--light {
  background-color: white;
  border: 0.5px solid black;
}

.dropdown_hamburger_menu>a {
  font-size: 20px;
  color: white;
  text-decoration: none;
}

.dropdown_hamburger_menu>a:hover {
  text-decoration: underline;
}

.dropdown_hamburger_menu--light>a {
  color: black;
}

@media (max-width: 1024px) {
  .burger-icon {
    display: revert;
    color: white;
    cursor: pointer;
  }

  .burger-icon--light {
    color: black;
  }

  .connect_wallet {
    top: 20px;
    right: 20px;
    position: fixed;
  }

  .connect_wallet2 {
    top: 20px;
    right: 20px;
    position: fixed;
  }

  .connected_wallet_address {
    align-items: right;
    font-weight: bold;
    display: flex;
    width: fit-content;
    right: 0;
    margin: 0;
  }

  .connected_wallet {
    padding-left: 5px;
    padding-right: 1px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
}

@media (max-height: 480px) {
  .dropdown_hamburger_menu {
    overflow: scroll;
  }

  .dropdown_hamburger_menu>a {
    font-size: 15px;
    margin: 0;
  }
}

@media only screen and (max-width: 390px) {
  .connect_wallet {
    padding: 2px 7px;
    margin: 0;
  }

  .connect_wallet2 {
    padding: 2px 7px;
    margin: 0;
  }

  /* hide wallet address & adjust spacing on very small screens */
  .address {
    display: none;
  }

  .connected_wallet {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 15px;
    width: 50px;
  }

  .connected_wallet--light {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 15px;
    width: 50px;
  }
}