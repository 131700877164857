.container {
    color: var(--color_text);
    padding: 30px 30px 120px 30px;
    overflow-y: auto;
    & > div {
        margin-bottom: 24px;
    }
}

.topRow {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 350px));
    grid-template-rows: 140px;
}
