/* * {
  outline: 1px solid red;
} */

body {
    margin: 0;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f6fa;
    font-family: "Poppins";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Thin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 100;
    src: url("./assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
}

/* Thin Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 100;
    src: url("./assets/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
}

/* ExtraLight */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

/* ExtraLight Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 200;
    src: url("./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
}

/* Light */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

/* Light Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    src: url("./assets/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
}

/* Regular */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

/* Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    src: url("./assets/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}

/* Medium */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

/* Medium Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    src: url("./assets/fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
}

/* Semi-Bold */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

/* Semi-Bold Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 600;
    src: url("./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
}

/* Bold */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    src: url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

/* Bold Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 700;
    src: url("./assets/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
}

/* Extra-Bold */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

/* Extra-Bold Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 800;
    src: url("./assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
}

/* Black */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    src: url("./assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
}

/* Black Italic */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    src: url("./assets/fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
}
