.dropdown_menu {
    cursor: default;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #043b71;
    color: var(--color_text);
}

.dropdown_menu--light {
    border-top: 1px solid rgb(210, 232, 239);
}

.basic_container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 30px;
    padding: 40px;
}

.type_tab {
    display: none;
}

.type_selector {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    width: fit-content;
    color: var(--color_grey);
    min-width: 170px;
}

.type_selector p {
    margin: 0px;
    padding: 8px 0px;
    border-bottom: 1px solid var(--color_grey);
    cursor: pointer;
    line-height: 52px;
    transition: all 0.3s ease-in;
}

.type_selector p:last-child {
    border-bottom: none;
}

.type_selector p.active {
    color: var(--color_primary);
    font-size: 40px;
}

.right_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.see_details_dropdown {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: white;
}

.see_details_dropdown:hover {
    cursor: pointer;
}

.see_details_dropdown--light {
    color: black;
}

.see_details_description {
    color: white;
    font-size: 17px;
    font-weight: 600;
    padding: 10px 5px;
}

.see_details_description--light {
    color: black;
    font-weight: 700;
}

@media (max-width: 1200px) {
    .basic_container {
        grid-template-columns: 1fr;
    }

    .type_tab {
        display: block;
    }

    .type_selector {
        display: none;
    }
}

@media (max-width: 768px) {
    .see_details_description {
        font-size: 13px;
    }

    .basic_container {
        padding: 10px;
    }

    .dropdown_menu {
        font-size: 14px;
    }
}
