.page {
  background-color: #001428;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  position: fixed;
}

.modal-content {
  z-index: 9999999999f !important;
}

.page--light {
  background-color: #f5f6fa;
  width: 100vw;
  height: 100vh;
}

.ac_page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.sidebar {
  flex: 2;
  max-width: 256px;
}

.burger-menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000055;
  backdrop-filter: blur(2px);
  z-index: 1500;
}

.burger-menu {
  position: fixed;
  right: 100%;
  max-width: 230px;
  z-index: 1600;
  transition-duration: 0.4s;
}

.burger-menu-open {
  transform: translateX(100%);
}

.rightside {
  flex: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rightside>p {
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.rightside--light>p {
  color: var(--color_primary);
}

.hamburger_menu {
  display: none;
}



@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}