///// Background Colors /////
$background-primary: #001428;
$background-secondary: #012243;
$background-secondary-hover: #032a51;
$background-dark: #000c18;
$background-light: #8798ad46;

///// Colors /////
$color-primary: #009aff;
$color-grey: #979797;
$color-white: #ffffff;
$color-black: #000000;
$color-text: #ffffff;

///// Border Colors /////
$border-color: #043b71;
$border-color-light: #d2e8ef;

[data-lightMode=true] {
  /* ///// Background Colors ///// */
  $background-primary: #f5f6f9;
  $background-secondary: #ffffff;
  $background-secondary-hover: #fbfbfb;
  $background-dark: #68b4c9;
  $background-light: #8798ad46;

  /* ///// Colors ///// */
  $color-primary: #63cce0;
  $color-grey: #8798ad;
  $color-white: #ffffff;
  $color-black: #000000;
  $color-text: #000000;
  
  /* ///// Border Colors ///// */
  $border-color: #d9d9d9;
  $border-color-light: #d2e8ef;
}